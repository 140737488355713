const DEV = process.env.NODE_ENV === 'development';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

export default {
	init() {
		function isTouchDevice() {
			return (
				'ontouchstart' in window ||
				navigator.maxTouchPoints > 0 ||
				navigator.msMaxTouchPoints > 0
			);
		}

		jQuery(document).ready(function ($) {
			const diagram = $('#diagramBlock');
			const blocks = $('.diagram_block');
			const arrows = $('.arrow_desktop');

			// Solo se l'elemento diagram esiste e non siamo su dispositivo touch
			if (
				diagram.length &&
				blocks.length &&
				arrows.length &&
				!isTouchDevice()
			) {
				// Configurazione iniziale - imposta tutto invisibile
				gsap.set(blocks, { opacity: 0 });

				// Imposta dimensioni iniziali delle frecce in base alla loro direzione
				arrows.each(function () {
					const isVertical = $(this).hasClass(
						'arrow_desktop_vertical'
					);
					gsap.set(this, {
						opacity: 0,
						[isVertical ? 'height' : 'width']: 10,
					});
				});

				// Crea la timeline principale con ScrollTrigger
				const mainTimeline = gsap.timeline({
					scrollTrigger: {
						trigger: diagram,
						start: 'top 80%',
					},
				});

				// Primi 4 blocchi e frecce
				const firstBlocks = blocks.slice(0, 4);
				const firstArrows = arrows.slice(0, 4);

				// Anima primi 4 blocchi
				mainTimeline.to(
					firstBlocks,
					{
						opacity: 1,
						duration: 0.5,
						stagger: 0.6,
					},
					'start'
				);

				// Anima prime 4 frecce (opacità e dimensioni)
				mainTimeline.to(
					firstArrows,
					{
						opacity: 1,
						duration: 0.2,
						stagger: 0.6,
					},
					'start+=0.5'
				);

				mainTimeline.to(
					firstArrows,
					{
						width: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? $(target).width()
								: '100%';
						},
						height: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? '100%'
								: $(target).height();
						},
						duration: 0.3,
						stagger: 0.6,
					},
					'start+=0.6'
				);

				// Anima il quinto blocco
				mainTimeline.to(
					blocks.eq(4),
					{
						opacity: 1,
						duration: 0.5,
					},
					'fifthBlock'
				);

				// Anima insieme le frecce 4 e 5
				mainTimeline.to(
					[arrows.eq(4), arrows.eq(5)],
					{
						opacity: 1,
						duration: 0.2,
					},
					'fifthBlock'
				);

				mainTimeline.to(
					[arrows.eq(4), arrows.eq(5)],
					{
						width: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? $(target).width()
								: '100%';
						},
						height: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? '100%'
								: $(target).height();
						},
						duration: 0.3,
					},
					'fifthBlock+=0.2'
				);

				// Anima i blocchi conclave (5-6) e l'ultimo blocco
				mainTimeline.to(
					blocks.slice(5, 7),
					{
						opacity: 1,
						duration: 0.5,
					},
					'secondGroup'
				);
				mainTimeline.to(
					blocks.eq(blocks.length - 1),
					{
						opacity: 1,
						duration: 0.5,
					},
					'secondGroup+=0.6'
				);

				// Anima freccia curva
				mainTimeline.to(
					'#arrowCL',
					{
						height: 0,
						duration: 0.2,
					},
					'secondGroup'
				);
				mainTimeline.to(
					'#arrowCT',
					{
						width: 0,
						duration: 0.4,
					},
					'secondGroup+=0.2'
				);
				mainTimeline.to(
					'#arrowCR',
					{
						height: 0,
						duration: 0.2,
					},
					'secondGroup+=0.6'
				);

				// Anima l'ultima freccia
				const lastArrow = arrows.eq(arrows.length - 1);
				mainTimeline.to(
					lastArrow,
					{
						opacity: 1,
						duration: 0.2,
					},
					'secondGroup+=0.3'
				);

				mainTimeline.to(
					lastArrow,
					{
						width: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? $(target).width()
								: '100%';
						},
						height: function (i, target) {
							return $(target).hasClass('arrow_desktop_vertical')
								? '100%'
								: $(target).height();
						},
						duration: 0.3,
					},
					'secondGroup+=0.6'
				);
			}
		});
	},
	finalize() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	},
};
